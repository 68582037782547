//
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import "./Menu.scss";
import { FiHome, FiGrid, FiPhone, FiClock, FiSettings, FiFilter, FiBold, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BsPerson, BsChatSquareQuote } from "react-icons/bs";
import { AiOutlineTwitter, AiFillInstagram, AiFillLinkedin, AiFillGithub, AiFillFacebook } from "react-icons/ai";
import { CgInsights } from "react-icons/cg";
function Header() {
 
  const [servicesOpen, setServiceOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [insOpen, setInsOpen] = useState(false);
  const [navSize, setnavSize] = useState("5rem");
  const [navColor, setnavColor] = useState("transparent");
  const [ navlistColor ,setnavlistColor]= useState("#293462")
  const [img, setImg] = useState(<img src="../../asessts/images/logo/logo.png" style={{ width: "auto", height: "50px", marginTop: "0px" }} />);
  const [shadow, setShadow] = useState("");
  const [select, setSelect] = useState();
  const [toggle, setToggle] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState();
  const [accordion, setAccordion] = useState();
  const [accordion1, setAccordion1] = useState();
  const [accordion2, setAccordion2] = useState();
  const listenScrollEvent = () => {
    window.scrollY > 0 ? setnavColor("#fff") : setnavColor(" #293462");
    window.scrollY > 0 ? setnavlistColor("#293462") : setnavlistColor(" #fff");
    window.scrollY > 0 ? setnavSize("5rem") : setnavSize("5rem");
    window.scrollY > 0 ? setShadow("0px 0px 10px rgba(0,0,0,0.1)") : setShadow("");
    window.scrollY > 0
      ? setImg(<img src="../../asessts/images/logo/logo.png" style={{ width: "auto", height: "42px", marginTop: "0px" }} />)
      : setImg(<img src="../../asessts/images/logo-01.png" style={{ width: "auto", height: "50px", marginTop: "0px" }} />);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  return (
    <>
      <div className="header header-fixed" style={{ zIndex: "3" }}>
        <div
          className="navbar container-fluid px-4"
          style={{
            backgroundColor: navColor,
            height: navSize,
            transition: "all 1s",
            boxShadow: shadow,
          }}
        >
          <div className="logo">
            <a href="/">

              {img}
            </a>
          </div>
          <div>
            {window.innerWidth < 992 ? (
              <div id="mobile-menu">
                <div id="nav-icon2" className={`${toggle ? "open" : ""}`} onClick={() => setToggle(!toggle)}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                {toggle ? (
                  <div className="backdrop">
                    <div className="menu-mobile">
                      <div className="px-3">
                        <Link to="/" onClick={() => setToggle(!toggle)} className="mobile-links" >
                          <div>
                            <FiHome /> &nbsp; Home
                          </div>
                        </Link>
                        <Link to="/about-us" onClick={() => setToggle(!toggle)} className="mobile-links">
                          <div >
                            <BsPerson /> &nbsp; About Us
                          </div>
                        </Link>
                        <Link to="/timeline" onClick={() => setToggle(!toggle)} className="mobile-links">
                          <div>
                            <FiClock /> &nbsp; Timeline
                          </div>
                        </Link>
                        <Link to="/portfolio" onClick={() => setToggle(!toggle)} className="mobile-links">
                          <div>
                            <FiGrid /> &nbsp; Portfolio
                          </div>
                        </Link>
                        <Link to="/get-a-quote" onClick={() => setToggle(!toggle)} className="mobile-links">
                          <div>
                            <BsChatSquareQuote /> &nbsp; Get a Quote
                          </div>
                        </Link>
                        <div className="mobile-links my-4 ps-0 d-flex justify-content-between" onClick={() => setAccordion(!accordion)}>
                          <div>
                            <FiSettings /> &nbsp; Services
                          </div>
                          <div>{accordion ? <FiChevronUp /> : <FiChevronDown />}</div>
                        </div>
                        {accordion ? (
                          <div className="border-top">
                            <Link to="/website-development" className="nav-link" onClick={() => setToggle(!toggle)}>
                              <span className="p-2">Website Development</span>
                            </Link>

                            <Link onClick={() => setToggle(!toggle)} className="nav-link" to="/custom-software-development-services">
                              <span className="p-2">Software Development</span>
                            </Link>

                            <Link onClick={() => setToggle(!toggle)} className="nav-link" to="/unity-game-development-company">
                              <span className="p-2"> Unity Development</span>
                            </Link>
                            <Link onClick={() => setToggle(!toggle)} className="nav-link" to="/mobile-development">
                              <span className="p-2"> Mobile App Development </span>
                            </Link>
                            <Link onClick={() => setToggle(!toggle)} className="nav-link" to="/affordable-seo-services-uk">
                              <span className="p-2">Search Engine Optimization </span>
                            </Link>
                             <Link onClick={() => setToggle(!toggle)} className="nav-link" to="/wordpress-development-services">
                              <span className="p-2">Wordpress Development</span>
                            </Link> 
                            <Link onClick={() => setToggle(!toggle)} to="/shopify-development-services" className="nav-link">
                              <span className="p-2">Shopify Devlopment</span>
                            </Link>
                            {/* <Link onClick={() => setToggle(!toggle)} to="/DigitalMarketing" className="nav-link">
                              <span className="p-2">DigitalMarketing</span>
                            </Link>  */}
                            <Link onClick={() => setToggle(!toggle)} to="/.Net-development-services" className="nav-link">
                              <span className="p-2">.Net Devlopment</span>
                            </Link> 
                            <Link onClick={() => setToggle(!toggle)} className="nav-link" to="/quotation">
                              <span className="p-2">Quotation</span>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <div className="mobile-links my-4 ps-0 d-flex justify-content-between" onClick={() => setAccordion1(!accordion1)}>
                            <div>
                              <FiFilter /> &nbsp; Products
                            </div>{" "}
                            <div>{accordion1 ? <FiChevronUp /> : <FiChevronDown />}</div>
                          </div>
                          {accordion1 ? (
                            <div className="border-top">
                              <Link onClick={() => setToggle(!toggle)} className="nav-link py-3" to="/hrm">
                                <span className="p-2">HRM</span>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <div className="mobile-links my-4 ps-0 d-flex justify-content-between" onClick={() => setAccordion2(!accordion2)}>
                            <div>
                            <CgInsights /> &nbsp; Insight
                            </div>{" "}
                            <div>{accordion2 ? <FiChevronUp /> : <FiChevronDown />}</div>
                          </div>
                          {accordion2 ? (
                            <div className="border-top pb-3">
                              <Link onClick={() => setToggle(!toggle)} className="nav-link py-3" to="/Referral-Program">
                                <span className="p-2">Referral Program</span>
                              </Link>
                              <Link onClick={() => setToggle(!toggle)} className="nav-link py-3" to="/our-packages">
                                <span className="p-2">Our Package</span>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <a href="/blog" className="my-4 ps-0 mobile-links">
                          <FiBold /> &nbsp; Blog
                        </a>
                        <Link onClick={() => setToggle(!toggle)} to="/contact-us" className="mobile-links">
                          <div className="my-4">
                            <FiPhone /> &nbsp; Contact Us
                          </div>
                        </Link>
                      </div>
                      <div className="social-links">
                        <a className="bottom-link" href="https://twitter.com/wellcreator" target="_blank">
                          <AiOutlineTwitter size={30} />
                        </a>
                        <a className="bottom-link" href="https://www.instagram.com/wellcreator/" target="_blank">
                          <AiFillInstagram size={30} />
                        </a>
                        <a className="bottom-link" href="https://www.linkedin.com/company/wellcreator/" target="_blank">
                          <AiFillLinkedin size={30} />
                        </a>
                        <a className="bottom-link" href="https://github.com/wellcreator" target="_blank">
                          <AiFillGithub size={30} />
                        </a>
                        <a className="bottom-link" href="https://www.facebook.com/wellcreator" target="_blank">
                          <AiFillFacebook size={30} />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <nav className="menu ">
            <ul>
              <li className="mt-lg-2">
                <Link to="/" className={`px-3 nav-link text-uppercase ${select == 1 ? "active-color" : ""}`} onClick={() => setSelect(1)} style={{
                                      color: navlistColor,
                                    }}>
                  Home
                </Link>
              </li>
              <li className="mt-lg-2" >
                <NavDropdown
                  show={servicesOpen}
                  onMouseEnter={() => setServiceOpen(true)}
                  onMouseLeave={() => setServiceOpen(false)}
                  className="text-uppercase "
                  title="Services"
                  id="basic-nav-dropdown"
                  style={{
                    color: navlistColor,
                  }}
                >
                  <Link className=" nav-link" to="/website-development"  >
                    Website Development
                  </Link>
                  <Link className=" nav-link" to="/custom-software-development-services" >
                    Software Development
                  </Link>
                  <Link className=" nav-link" to="/unity-game-development-company" >
                    Unity Development
                  </Link>
                  <Link className=" nav-link" to="/mobile-development" >
                    Mobile App Development
                  </Link>
                  <Link className=" nav-link" to="/affordable-seo-services-uk" >
                    SEO (Search Engine Optimization)
                  </Link>
                   <Link to="/wordpress-development-services" className="nav-link" >
                    Wordpress Devlopment
                  </Link> 
                  <Link to="/shopify-development-services" className="nav-link" >
                    Shopify Devlopment
                  </Link>
                   <Link to="/.Net-development-services" className="nav-link" >
                    .Net Devlopment
                  </Link>
                   {/* <Link to="/DigitalMarketing" className="nav-link">
                    Digital Marketing
                  </Link>  */}
                  {/* <Link to="/quotation" className="nav-link">
                    Quotation
                  </Link> */}
                </NavDropdown>
              </li>
              <li className="mt-lg-2">
                <Link to="/about-us" className={`px-3 nav-link text-uppercase ${select == 2 ? "active-color" : ""}`}onClick={() => setSelect(2)}   style={{
                                      color: navlistColor,
                                    }}>
                  About Us
                </Link>
              </li>

              <li className="mt-lg-2">
                <Link to="/portfolio" className={`px-3 nav-link text-uppercase ${select == 3 ? "active-color" : ""}`} onClick={() => setSelect(3)}  style={{
                                      color: navlistColor,
                                    }}>
                  Portfolio
                </Link>
              </li>

              <li className="mt-lg-2">
                <NavDropdown show={newOpen} onMouseEnter={() => setNewOpen(true)} onMouseLeave={() => setNewOpen(false)} className="text-uppercase" title="Products" id="basic-nav-dropdown"  style={{
                                      color: navlistColor,
                                    }}>
                  <Link to="/hrm" className=" nav-link">
                    HRM
                  </Link>
                </NavDropdown>
              </li>
              <li className="mt-lg-2">
                <a href="/blog" className={`px-3 nav-link text-uppercase ${select == 4 ? "active-color" : ""}`} onClick={() => setSelect(4)}  style={{
                                      color: navlistColor,
                                    }}>
                  Blog
                </a>
              </li>
              <li className="mt-lg-2">
                <Link to="/contact-us" className={`px-3 nav-link text-uppercase ${select == 6 ? "active-color" : ""}`} onClick={() => setSelect(6)}  style={{
                                      color: navlistColor,
                                    }}>
                  Contact us
                </Link>
              </li>
              <li className="mt-lg-2">
                <NavDropdown show={insOpen} onMouseEnter={() => setInsOpen(true)} onMouseLeave={() => setInsOpen(false)} className="text-uppercase" title="Insight" id="basic-nav-dropdown"  style={{
                                      color: navlistColor,
                                    }}>
                  <Link to="/Referral-Program" className=" nav-link"  >
                    REFERRALS
                  </Link>
                  <Link  className="nav-link " to="/our-packages">
                                Our Packages
                              </Link>
                </NavDropdown>
              </li>
              <a href="/get-a-quote">
                <button type="button" className="custom-btn-1 top-button rounded-pill ps-2  pe-0 py-2">
                  <span>Get a Quotation</span>
                </button>
              </a>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
