// Libraries
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TimeLine from "../Pages/TimeLine";
import "./Animation.css";
import first from "../Components/Seo-Copmonents/first.jsx"



const AboutUs = lazy(() => import("../Pages/AboutUs"));
const Portfolio = lazy(() => import("../Pages/Portfolio"));
const ContactUs = lazy(() => import("../Pages/ContactUs"));
const Thanks = lazy(() => import("../Pages/Thanks"));
const WebsiteDevelopment = lazy(() => import("../Pages/WebsiteDevelopment"));
const SoftwareDevelopment = lazy(() => import("../Components/Software-dev/SoftwareDevelopment.js"));
const UnityDevelopment = lazy(() => import("../Pages/UnityDevelopment"));
const MobileDevelopment = lazy(() => import("../Pages/MobilDevelopment/MobileDevelopment.js"));

const HRM = lazy(() => import("../Pages/HRM"));
const TermsConditions = lazy(() => import("../Pages/Terms&Condition"));
const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
const Quotation = lazy(() => import("../Pages/Quotation"));
const FAQs = lazy(() => import("../Pages/FAQs"));
const Wordpress = lazy(() => import("../Pages/Wordpress/Wordpress.js"));
const Shopify = lazy(() => import("../Pages/Shopify/Shopify.js"));
const WebHosting = lazy(() => import("../Pages/Webhosting/Webhosting"));
const Checkout = lazy(() => import("../Pages/Webhosting/Checkout.js"));
const Home = lazy(() => import("../Pages/Home"));
const QuoteInside = lazy(() => import("../Pages/QuotationInside/QuotationInside.js"));
const RefundPolicy = lazy(() => import("../Pages/Privacy-refund/Privacyrefund.js"))
const SiteMap = lazy(() => import("../Pages/SiteMap.js"));
const DotNet = lazy(() => import("../Pages/DotNet/Dotnet.js"))
const Referals = lazy(() => import("../Pages/Referals/Referal.js"))
const DigitalMarketing =lazy(() => import ("../Pages/DigitalMarket/Digitalmarketing.js"))
// Team routes developed by Abdul Rehman
const Team = lazy(() => import ("../Pages/Team/Team.js"))
// career module routes
const OurPackage = lazy(() => import ("../Pages/OurPackage/Ourpackage.jsx"))
const Admin = lazy(() => import("../Pages/Career/Admin"));
const Career = lazy(() => import("../Pages/Career/Career.js"));
const CareerInside = lazy(() => import("../Pages/Career/CareerInside.js"));
const Thankyou = lazy(() => import("../Pages/Career/Thankyou.js"));
const Sasswebpage = lazy(() => import("../Pages/sassWebdevelopment.js"));

// Seo Pages Routes
const SeoPage = lazy(() => import("../Pages/SeoPage.js"))

function WebsiteRoutes() {
  return (
    <>
      <Suspense
        fallback={
          <div className="d-flex justify-content-center">
            <div class="loadingio-spinner-ripple-9yaj8a57f0u">
              <div class="ldio-24eutxqm1o1">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        }
      >

        <Routes>
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/timeline" element={<TimeLine />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/thank-you" element={<Thanks />} />
          <Route exact path="/website-development" element={<WebsiteDevelopment />} />
          <Route exact path="/custom-software-development-services" element={<SoftwareDevelopment />} />
          <Route exact path="/unity-game-development-company" element={<UnityDevelopment />} />
          <Route exact path="/mobile-development" element={<MobileDevelopment />} />
          {/* new sass page */}
          <Route exact path="/sass-webpage" element={<Sasswebpage />} />
          <Route exact path="/sitemap" element={<SiteMap />} />

          {/* SEO Page routers */}
          <Route exact path="/affordable-seo-services-uk" element={<SeoPage />}>
            <Route path="first" element={< first />} />
          </Route>

          {/* .Net Page Route */}
          <Route extend path="/.Net-development-services" element={<DotNet />} />
          <Route extend path="/DigitalMarketing" element={<DigitalMarketing/>} />
          <Route extend path="/Referral-Program" element={<Referals />} />
          {/* privacy Refund */}
          <Route extend path="/RefundPolicy" element={<RefundPolicy />} />
             {/* { Team Route} */}
          <Route extend path="/Team" element={<Team />} />
          <Route extend path="/our-packages" element={<OurPackage/>}/>
          {/*  */}
          <Route exact path="/hrm" element={<HRM />} />
          <Route exact path="/terms-conditions" element={<TermsConditions />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route extend path="/quotation" element={<Quotation />} />
          <Route extend path="/faqs" element={<FAQs />} />
          <Route extend path="/wordpress-development-services" element={<Wordpress />} />
          <Route extend path="/shopify-development-services" element={<Shopify />} />
          <Route extend path="/hosting" element={<WebHosting />} />
          <Route extend path="/product-hosting" element={<Checkout />} />
          <Route extend path="/get-a-quote" element={<QuoteInside />} />
          <Route exact path="/" element={<Home />} />


          {/* Career module routes  */}
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/admin/all-jobs" element={<Admin />} />
          <Route exact path="/admin/create-job" element={<Admin />} />
          <Route exact path="/admin/applicants" element={<Admin />} />
          <Route exact path="/career" element={<Career />} />
          <Route extend path="/apply-job" element={<CareerInside />} />
          <Route extend path="/thankyou" element={<Thankyou />} />



          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense >
    </>
  );
}
export default WebsiteRoutes;
